<template>
<el-dialog v-model="visible" title="下载条码" custom-class="batch-download-dialog" :before-close="close">
  <div class="bdd-left" v-loading="loading">
    <div class="bdd-title">基本信息</div>
    <div class="bddl-item">
      <div class="bddli-line row-start-start">
        <div>供应商：</div>
        <span>{{ formData.txtManufacturerName }}</span>
      </div>
      <div class="bddli-line row-start-start">
        <div>检测类型：</div>
        <span>{{ formData.txtCategoryName }}</span>
      </div>
    </div>
    <div class="bdd-title">批次信息</div>
    <div class="bddl-item">
      <div class="bddli-line row-center-start">
        <div>生产年份：</div>
        <span>{{ formData.txtBatchYear }}</span>
      </div>
      <div class="bddli-line row-center-start">
        <div>生产月份：</div>
        <span>{{ formData.txtBatchMonth }}</span>
      </div>
      <div class="bddli-line row-center-start">
        <div>生产批次：</div>
        <span>{{ formData.txtBatchSerial }}</span>
      </div>
    </div>
  </div>
  <div class="bdd-right column-center-center">
    <svg id="canvascode"></svg>
    <el-button type="primary" @click="dowloadSvg()">下载</el-button>
  </div>
</el-dialog>
</template>

<script>
// 批次管理 - 下载条码
import JsBarcode from 'jsbarcode';
import { onMounted, reactive, toRefs } from 'vue';
export default {
  props: {
    barData: { type: Object },
  },
  setup(prop, ctx) {
    let moment = require("moment");
    const state = reactive({
      visible: true,
      loading: false,
      formData: {},
      option: {
        fontSize: 16,
        color: "#000000",
        width: 3.5,
        height: 140
      },
      show: false,
    })

    onMounted(() => {
      state.loading = true;
      state.formData = prop.barData;
      setTimeout(() => {
        JsBarcode("#canvascode", prop.barData.txtBarCode, state.option)
        state.show = true;
        state.loading = false;
      }, 800)
    })

    // 下载条形码
    const dowloadSvg = () => {
      // 获取生成的 SVG 条形码元素
      let node = document.getElementById('canvascode');
      if (node) {
        // 调用 covertSVG2Image 函数时，确保传入正确的参数
        covertSVG2Image(node, `${prop.barData.txtBarCode}-${moment(new Date()).format('YYYY-MM-DD')}`, node.clientWidth, node.clientHeight);
      }
    };

    // 图片导出
    const covertSVG2Image = (svgNode, name, width, height, type = 'png') => {
      let serializer = new XMLSerializer();
      let source = '<?xml version="1.0" standalone="no"?>\r\n' + serializer.serializeToString(svgNode);
      let image = new Image();
      image.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source);

      let canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      let context = canvas.getContext('2d');

      image.onload = function () {
        // 确保绘制图像时，图像的宽高与 canvas 的宽高一致
        context.drawImage(image, 0, 0, width, height);
        let a = document.createElement("a");
        a.download = `${name}.${type}`;
        // 确保 toDataURL 的类型是小写的
        a.href = canvas.toDataURL(`image/${type}`);
        a.click();
      }

    }

    const close = () => {
      ctx.emit("close");
    };

    return {
      ...toRefs(state),
      close,
      dowloadSvg,
      covertSVG2Image
    }
  }
}
</script>

<style lang="scss">
.batch-download-dialog {
  width: 45%;
  height: calc(100% - 50vh);

  .el-dialog__body {
    display: flex;
    flex-direction: row;
    align-items: center;

    .bdd-title {
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    .bdd-left {
      height: 100%;
      width: 50%;

      .bddl-item {
        padding: 6px 0 6px 20px;

        .bddli-line {
          font-size: 14px;
          margin-bottom: 16px;

          div {
            width: 80px;
            font-weight: bold;
          }
        }
      }
    }

    .bdd-right {
      width: 45%;
      height: 100%;

      #canvascode {
        width: 100%;
      }

      .el-button {
        margin-top: 8px;
      }
    }
  }
}
</style>
